html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {

  background-color: $very-dark-grey;
  color: $white;
  
  font-family: "Open Sans", Sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
  //  padding: 10px; //temp: for image only
    @include media-breakpoint-up(sm) {
   //   padding: 20px;
    }
  }

  p {
    margin:0;
    padding:0;
  }

}

.motd-wrapper {
  text-align: center;
}

.motd-title {
  font-size: .9em;
  font-weight: bold;
  padding-bottom: 5px;
  @include media-breakpoint-up(sm) {
    font-size: 1.1em;
    padding-bottom: 10px;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.5em;
    padding-bottom: 20px;
  }
}

.motd-message {
  font-size: .8em;
  padding-top: 8px;
  display: inline-block;
  max-width: 80%;
  @include media-breakpoint-up(sm) {
    font-size: .9em;
    padding-top: 10px;
    max-width: 60%;
  }
  @include media-breakpoint-up(md) {
    font-size: 1.3em;
  }
}

.motd-image-wrapper {
  display: block;
}

.motd-image {
  height: 60vh;
  
  @include media-breakpoint-up(md) {
    max-width: 70vh;
  }

  @include media-breakpoint-up(lg) {
    max-width: 80vh;
  }
}

.motd-image-only {
  width: 100vw;  
}